import { useContext } from "react";
import css from "./modalHistoryPassager.module.scss";
import { useHistoryPassager } from "../../../hooks/useBooking";
import getTokenContext from "../../../context/token.context";

export default function ModalHistoryPassager({ formik, setIsModalOpened }) {
  const tokenContext = useContext(getTokenContext);

  const { data: historyPassager } = useHistoryPassager(tokenContext);

  const setPassager = (passager) => {
    formik.setValues((prevState) => ({
      ...prevState,
      name: passager.FirstName,
      lastName: passager.LastName,
      dni: passager.Identification,
    }));
    setIsModalOpened(false);
  };

  const asd = [];

  return (
    <div className={css.boxPassager}>
      {historyPassager && historyPassager.length > 0 ? (
        <>
          <h3>Seleccione pasajero</h3>
          {historyPassager.map((passager) => (
            <button
              onClick={() => setPassager(passager)}
              className={css.containerPassager}
            >
              <div className={css.information}>
                <div>
                  <strong>Nombre: </strong>
                  {` ${passager.FirstName}`}
                </div>
              </div>
              <div className={css.information}>
                <div>
                  <strong>Apellido: </strong>
                  {` ${passager.LastName}`}
                </div>
              </div>
              <div className={css.information}>
                <div>
                  <strong>DNI: </strong>
                  {` ${passager.Identification}`}
                </div>
              </div>
            </button>
          ))}
        </>
      ) : (
        <h2>No hay registros historicos</h2>
      )}
    </div>
  );
}
