import React from "react";

import { monthNames } from "../../../constants/objects";
import "./filters.modules.scss";
import Button from "../../share/button/Button";

export default function Filters({
  setfiltermonth,
  filtermonth,
  setIsModalOpened,
  months,
  width,
}) {
  const breakpoint = 1025;

  const getMonthName = (idMonth) => {
    return monthNames[idMonth];
  };

  const changeMonth = (i, month) => {
    if (width < breakpoint) {
      if (i === 0) {
        months[0].Number !== filtermonth && setfiltermonth(filtermonth - 1);
      } else if (i === 2) {
        months[0].Number + 2 !== filtermonth && setfiltermonth(filtermonth + 1);
      }
    } else {
      setfiltermonth(month.Number);
    }
  };

  return (
    <div className="containerFilters">
      <div className="filters">
        {months.map((month, i) => (
          <Button
            key={month.Number}
            type="button"
            className={` ${i === 1 ? "showMonthMobile" : "showArrowMobile"} ${
              filtermonth === month.Number ? "active" : null
            }`}
            onClick={() => {
              changeMonth(i, month);
            }}
          >
            <span>
              {width < breakpoint
                ? i === 0
                  ? "<"
                  : i === 1
                  ? getMonthName(filtermonth)
                  : i === 2
                  ? ">"
                  : null
                : month.Name}
            </span>
          </Button>
        ))}
      </div>
      <Button
        onClick={() => setIsModalOpened("myReservation")}
        type="button"
        className="butonOrange"
      >
        Ver todas mis reservas
      </Button>
    </div>
  );
}
