import React, { useContext, createContext, useState, useEffect } from "react";
import styles from "./grilla.module.scss";
import clsx from "clsx";
import checkSuccess from "../../../images/VectorcheckSuccess.svg";
import Button from "../../share/button/Button";

const GrillaItemContext = createContext();

export const checkPreview = (dayBooking, previewBookingData) => {
  let travelBookingDay = {};
  if (previewBookingData !== undefined) {
    const previewDay =
      previewBookingData.length > 0 &&
      previewBookingData.filter(
        (booking) =>
          booking.DayNumber == dayBooking.DayMonthNumber &&
          booking.Month === dayBooking.Month
      );
    if (previewDay.length > 0) {
      travelBookingDay = previewDay[0];
    }
  }
  return travelBookingDay;
};

const Grilla = ({ children, ...props }) => {
  const style = clsx([styles["grilla"]]);

  return (
    <div className={style} {...props}>
      {children}
    </div>
  );
};

const HeaderGrilla = ({ dataHeader, width, ...props }) => {
  const breakpoint = 500;
  const style = clsx([styles["grilla-header"], styles["grilla-card-day"]]);

  return dataHeader.map((data) => (
    <div key={data.day} className={style} {...props}>
      <p>{width < breakpoint ? data.day.substr(0,3) : data.day}</p>
    </div>
  ));
};

const ItemGrillaContent = ({
  children,
  dayBooking,
  previewBookingData,
  onClick,
  travelBookingPreviewReservationModel,
  isPreviewActive,
  filtermonth,
  setfiltermonth,
  ...props
}) => {
  let travelBookingDay = checkPreview(dayBooking, previewBookingData);

  const cardDayClass =
    travelBookingPreviewReservationModel &&
    Object.keys(travelBookingDay).length > 0
      ? travelBookingDay.DayNumber == dayBooking.DayMonthNumber &&
        travelBookingDay.Disponibility
        ? "grilla-preview-success"
        : "grilla-preview-fail"
      : dayBooking.Disponibility
      ? "grilla-success"
      : "grilla-without-date";

  const logicOnClick = () =>
    dayBooking.Disponibility
      ? onClick(dayBooking.DayMonthNumber)
      : dayBooking.Month !== filtermonth
      ? setfiltermonth(
          dayBooking.NotificationDescription !== "" ? dayBooking.Month : filtermonth
        )
      : null;

  const style = clsx([
    styles["grilla-card-day"],
    styles[cardDayClass],
    isPreviewActive && styles["grilla-preview-active"],
  ]);

  return (
    <GrillaItemContext.Provider value={{ travelBookingDay }}>
      <div
        className={style}
        onClick={
          !isPreviewActive
            ? cardDayClass === "grilla-success" ||
              cardDayClass === "grilla-without-date"
              ? logicOnClick
              : null
            : null
        }
        {...props}
      >
        {children}
      </div>
    </GrillaItemContext.Provider>
  );
};

const ItemGrilla = ({
  children,
  dayBooking,
  previewBookingData,
  travelBookingPreviewReservationModel,
  onClick,
  setModifyItem,
  setfiltermonth,
  width,
  setSelectedDay,
  ...props
}) => {
  const breakpoint = 1025;

  const [previewData, setPreviewData] = useState(
    checkPreview(dayBooking, previewBookingData)
  );
  const { travelBookingDay } = useContext(GrillaItemContext);

  useEffect(() => {
    setPreviewData(checkPreview(dayBooking, previewBookingData));
  }, [previewBookingData]);

  const styleMessage =
    travelBookingPreviewReservationModel && Object.keys(previewData).length > 0
      ? travelBookingDay.Disponibility
        ? "dayPreviewOk"
        : "dayPreviewNoOk"
      : !dayBooking.Disponibility
      ? dayBooking.BookingDescription
        ? "hasBooking"
        : ""
      : "withoutMessage";

  const style = clsx(styles["messageTransport"], styles[styleMessage]);

  if (
    (travelBookingPreviewReservationModel &&
      Object.keys(previewData).length > 0) ||
    dayBooking.BookingDescription !== "" ||
    dayBooking.NotificationDescription !== ""
  ) {
    return (
      <>
        <button
          className={style}
          {...props}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedDay(dayBooking.DayMonthNumber)
            styleMessage === "withoutMessage"
              ? !travelBookingPreviewReservationModel &&
                onClick("myReservationDate")
              : !travelBookingPreviewReservationModel &&
                setfiltermonth((prev) =>
                  dayBooking.Month !== prev
                    ? dayBooking.NotificationDescription !== ""
                      ? dayBooking.Month
                      : prev
                    : prev
                );
          }}
        >
          {children}
          {(dayBooking.BookingDescription !== "" &&
            travelBookingPreviewReservationModel &&
            Object.keys(previewData).length > 0) ||
            (dayBooking.BookingDescription && (
              <>
                <img alt="check success" src={checkSuccess} />{" "}
              </>
            ))}
          <span className={styles.textDescriptionBooking}>
            {travelBookingPreviewReservationModel &&
            Object.keys(previewData).length > 0
              ? width > 550
                ? previewData.Comment
                : `${previewData.Comment.substr(0, 8)}...`
              : dayBooking.Disponibility
              ? width < breakpoint
                ? dayBooking.BookingDescription.substr(0, 1)
                : dayBooking.BookingDescription
              : width > breakpoint
              ? dayBooking.BookingDescription
                ? dayBooking.BookingDescription
                : dayBooking.NotificationDescription
              : dayBooking.BookingDescription.substr(0, 1)}
          </span>
        </button>
        {travelBookingPreviewReservationModel &&
          Object.keys(previewData).length > 0 &&
          !previewData.Disponibility && (
            <Button
              className={clsx(styles["modifyButton"])}
              onClick={() => {
                onClick("Modify");
                setModifyItem(previewData);
              }}
            >
              {width > breakpoint ? "Modificar" : "Modif.."}
            </Button>
          )}
      </>
    );
  }
};

const GrillaNamespace = Object.assign(Grilla, {
  Header: HeaderGrilla,
  ItemContent: ItemGrillaContent,
  Item: ItemGrilla,
});

export { GrillaNamespace as Grilla };
