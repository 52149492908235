import React from "react";
import "./customModal.scoped.scss";

export default function ModalContents({ isActive, children, handleClose, width, heigth = "auto" }) {
  if (isActive === true) {
    return (
      <>
        <div className="modal__overlay" onClick={handleClose}>
          <div style={{ width: width, height: heigth }} className="modal" onClick={(event) => event.stopPropagation()}>
            <button className="material-icons" onClick={handleClose}>
              x
            </button>
            {children}
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}
