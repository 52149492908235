import { useState, useContext, useEffect } from "react";
import CustomModal from "../../components/customModal/customModal";
import { useModalWithData } from "../../hooks/useModalWithData";
import Header from "../../components/transport/header/header";
import ModalAllReserv from "../../components/transport/modalAllReservation/modalAllReservation";
import ModalReservation from "../../components/transport/modalReservation/modalReservation";
import ModalTimes from "../../components/transport/modalTimes/modalTimes";
import Filters from "../../components/transport/filters/filters";
import { Grilla } from "../../components/transport/grilla/grilla";
import Loading from "../../components/loading/loading";
import styles from "./booking.module.scss";
import ContextSnackbar from "../../context/contextSnackbar";
import getTokenContext from "../../context/token.context";
import Button from "../../components/share/button/Button";
import { daysOfWeeks } from "../../constants/objects";
// import { ReactQueryDevtools } from "react-query/devtools";
import {
  useScheduleByMonth,
  usePreviewBooking,
  useConfirmBooking,
  usePreviewBookingModification,
  useMonths,
} from "../../hooks/useBooking";
import clsx from "clsx";

export default function Transport() {
  const tokenContext = useContext(getTokenContext);

  const snackbar = useContext(ContextSnackbar);

  const date = new Date();

  const [setIsModalOpened, isModalOpened] = useModalWithData();
  const [filtermonth, setfiltermonth] = useState(date.getMonth() + 1);
  const [currentDay, setCurrentDay] = useState(null);
  const [
    travelBookingPreviewReservationModel,
    setTravelBookingPreviewReservationModel,
  ] = useState(null);
  const [availablePreviewData, setAvailablePreviewData] = useState(false);
  const [modifyItem, setModifyItem] = useState({});
  const [selectedDay, setSelectedDay] = useState();
  const [previewDataState, setPreviewDataState] = useState(undefined);

  const {
    data: scheduleDataBooking,
    isLoading,
    refetch: refetchSchedule,
  } = useScheduleByMonth(filtermonth, tokenContext);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const { mutate: mutatePreviewBooking } = usePreviewBooking(tokenContext);

  const { data: Months } = useMonths(tokenContext);

  const { mutate: mutatePreviewModificationBooking } =
    usePreviewBookingModification(tokenContext);

  const { mutate: mutateConfirmBooking } = useConfirmBooking(tokenContext);

  const getPreview = (modifyPreview) => {
    const objectPreview = {
      year: date.getFullYear(),
      Month: modifyPreview.month,
      Day: modifyPreview.day,
      TimeId: modifyPreview.time,
      Recurrence: modifyPreview.recurrency,
      DaysToRecurrence: modifyPreview.recurrencyDays,
    };
    mutatePreviewBooking(objectPreview, {
      onSuccess: (dataBooking) => {
        setPreviewDataState(dataBooking);
        previewBookings({
          FirstName: modifyPreview.name,
          LastName: modifyPreview.lastName,
          Identification: modifyPreview.dni,
          UnitId: modifyPreview.unit,
          Bookings: dataBooking,
        });
        setAvailablePreviewData(
          dataBooking.filter((booking) => booking.Disponibility === false)
            .length === 0
        );
      },
    });
  };

  useEffect(() => {
    refetchSchedule(filtermonth);
  }, [filtermonth, refetchSchedule]);

  useEffect(() => {
    Months && setfiltermonth(Months[0].Number);
  }, [Months]);

  const getPreviewModification = (modifyPreview) => {
    const objectPreviewModify = {
      FirstName: travelBookingPreviewReservationModel.FirstName,
      LastName: travelBookingPreviewReservationModel.LastName,
      Identification: travelBookingPreviewReservationModel.Identification,
      UnitId: travelBookingPreviewReservationModel.UnitId,
      Bookings: modifyPreview,
    };
    mutatePreviewModificationBooking(objectPreviewModify, {
      onSuccess: (dataBooking) => {
        setPreviewDataState(dataBooking.Bookings);
        previewBookings(dataBooking);
        setAvailablePreviewData(
          dataBooking.Bookings.filter(
            (booking) => booking.Disponibility === false
          ).length === 0
        );
      },
    });
  };

  const previewBookings = (infoPreview) => {
    setTravelBookingPreviewReservationModel(infoPreview);
    setIsModalOpened(false);
  };

  const confirmbookings = () => {
    if (availablePreviewData) {
      mutateConfirmBooking(travelBookingPreviewReservationModel, {
        onSuccess: (dataConfirm) => {
          setTravelBookingPreviewReservationModel(null);
        },
        onError: (error) => {
          getPreviewModification(JSON.parse(error.response.data.Message).Bookings)
          snackbar.setSnackBar({
            status: "fail",
            message: JSON.parse(error.response.data.Message).Message,
          });
        },
      });
    } else {
      snackbar.setSnackBar({
        status: "fail",
        message:
          "Todos los horarios tienen que estar disponible, puedes modificarlo.",
      });
    }
  };

  if (!scheduleDataBooking) {
    return <Loading overlay={true} />;
  } else {
    return (
      <div className={styles.transportContainer}>
        <CustomModal
          isActive={!!isModalOpened}
          showWarning={true}
          width={isModalOpened === "Modify" ? "48%" : "70%"}
          handleClose={() => {
            setIsModalOpened(false);
          }}
        >
          {isModalOpened === "myReservation" ? (
            <ModalAllReserv />
          ) : isModalOpened === "myReservationDate" ? (
            <ModalAllReserv
              date={`${date.getFullYear()}${
                filtermonth < 10 ? `0${filtermonth}` : filtermonth
              }${selectedDay}`}
            />
          ) : isModalOpened === "reservations" ? (
            <ModalReservation
              filtermonth={filtermonth}
              currentDay={currentDay}
              previewBookings={previewBookings}
              cancelClick={() => {
                setIsModalOpened(false);
              }}
              getPreview={(Preview) => getPreview(Preview)}
            />
          ) : (
            <ModalTimes
              modifyItem={modifyItem}
              setModifyItem={setModifyItem}
              tokenContext={tokenContext}
              cancelClick={() => {
                setIsModalOpened(false);
              }}
              previewBookingData={previewDataState}
              getPreviewModification={(modifyPreview) =>
                getPreviewModification(modifyPreview)
              }
            />
          )}
        </CustomModal>
        <Header />
        {Months && (
          <Filters
            setfiltermonth={setfiltermonth}
            filtermonth={filtermonth}
            setIsModalOpened={() => setIsModalOpened("myReservation")}
            months={Months}
            width={width}
          />
        )}
        <Grilla>
          <Grilla.Header dataHeader={daysOfWeeks} width={width}/>
          {!isLoading &&
            scheduleDataBooking.map((dayBooking, index) => {
              return (
                <Grilla.ItemContent
                  key={dayBooking.DayMonthNumber + index}
                  dayBooking={dayBooking}
                  previewBookingData={previewDataState}
                  onClick={(day) => {
                    setCurrentDay(day);
                    setIsModalOpened("reservations");
                  }}
                  isPreviewActive={!!travelBookingPreviewReservationModel}
                  travelBookingPreviewReservationModel={
                    travelBookingPreviewReservationModel
                  }
                  filtermonth={filtermonth}
                  setfiltermonth={setfiltermonth}
                >
                  <span className={clsx([styles["number-day"]])}>
                    {dayBooking.DayMonthNumber}
                  </span>
                  <Grilla.Item
                    dayBooking={dayBooking}
                    previewBookingData={previewDataState}
                    travelBookingPreviewReservationModel={
                      travelBookingPreviewReservationModel
                    }
                    onClick={(idModal) => {
                      setIsModalOpened(idModal);
                    }}
                    setModifyItem={setModifyItem}
                    setfiltermonth={setfiltermonth}
                    filtermonth={filtermonth}
                    width={width}
                    setSelectedDay={setSelectedDay}
                  ></Grilla.Item>
                </Grilla.ItemContent>
              );
            })}
        </Grilla>
        {travelBookingPreviewReservationModel && (
          <div className={clsx([styles["containerButtons"]])}>
            <Button
              type="button"
              className={clsx([styles["buttonCancelar"]])}
              onClick={() => {
                setTravelBookingPreviewReservationModel(null);
              }}
            >
              DESCARTAR
            </Button>
            <Button
              // disabled={!availablePreviewData}
              type="button"
              className={clsx([
                styles["buttonContinuar"],
                // styles[availablePreviewData === false ? "disabled" : ""],
              ])}
              onClick={() => confirmbookings()}
            >
              FINALIZAR
            </Button>
          </div>
        )}
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </div>
    );
  }
}
