import * as React from "react";
import { classNames, ClassNameProp } from "../../../styles/classNames";
import styles from "./button.module.scss";

// export type ButtonProps = {
//   type?: 'submit' | 'reset' | 'button';
//   children: React.ReactNode;
//   disabled?: boolean;
//   className?: ClassNameProp;
//   onClick?: () => void;
// };

const Button = ({
  className,
  disabled = false,
  type,
  onClick,
  children,
  ...props
}) => {
  const componentProps = {
    className: classNames(styles.btn, className),
    ...props,
  };

  return (
    <button
      {...componentProps}
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
export default Button;
