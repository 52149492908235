import { useEffect, useState, createContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TransportPage from "./pages/transport/transport";
import ContextSnackbar from "./context/contextSnackbar";
import "./App.scss";

function App() {
  const [snackBar, setSnackBar] = useState({
    status: null,
    message: null,
    // status: "success",
    // message: "Se ha registrado correctamente"
  });

  useEffect(() => {
    snackBar.status !== null &&
      snackBar.message !== null &&
      setTimeout(() => {
        setSnackBar({
          status: null,
          message: null,
        });
      }, 3000);
  }, [snackBar]);

  useEffect(() => {
    snackBar.status !== null &&
      snackBar.message !== null &&
      setTimeout(() => {
        setSnackBar({
          status: null,
          message: null,
        });
      }, 3000);
  }, []);

  return (
    <div className="App">
      <div className="containerApp">
        <ContextSnackbar.Provider value={{ snackBar, setSnackBar }}>
          {snackBar.status !== null && (
            <div
              className={
                snackBar.status === "success"
                  ? "snackbarSuccess"
                  : "snackbarFail"
              }
            >
              <span>{snackBar.message}</span>
            </div>
          )}
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <a href="/booking">TRANSPORTE</a>
                  </>
                }
              />
              <Route path="booking" element={<TransportPage />} />
            </Routes>
          </BrowserRouter>
        </ContextSnackbar.Provider>
      </div>
    </div>
  );
}

export default App;
