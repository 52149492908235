import http from "../utils/http";
import { useQuery, useMutation, useQueryClient } from "react-query";

export function getTimeStop(routeId, stopId, date, tokenContext) {
  return http.get(
    `/travelBooking/routes/${routeId}/stops/${stopId}/times/${date}`,
    { useAuth: tokenContext }
  );
}

export function useTimeStop(routeId, stopId, date, tokenContext) {
  return useMutation(() => getTimeStop(routeId, stopId, date, tokenContext));
}

export function getUnit(tokenContext) {
  return http.get(`/travelBooking/units`, { useAuth: tokenContext });
}

export function useUnit(tokenContext) {
  return useQuery(["units"], () => getUnit(tokenContext), {
    refetchOnWindowFocus: false,
  });
}

export function getHistoryPassager(tokenContext) {
  return http.get(`/travelBooking/passengers`, { useAuth: tokenContext });
}

export function useHistoryPassager(tokenContext) {
  return useQuery(["passagers"], () => getHistoryPassager(tokenContext), {
    refetchOnWindowFocus: false,
  });
}

export function getMonths(tokenContext) {
  return http.get(`/travelBooking/months`, { useAuth: tokenContext });
}

export function useMonths(tokenContext) {
  return useQuery(["months"], () => getMonths(tokenContext), {
    refetchOnWindowFocus: false,
  });
}

export function getRoutes(tokenContext) {
  return http.get(`/travelBooking/routes`, { useAuth: tokenContext });
}

export function useRoutes(tokenContext) {
  return useQuery(["routes"], () => getRoutes(tokenContext), {
    refetchOnWindowFocus: false,
  });
}

export function getStopsByRoute(routeId, tokenContext) {
  return http.get(`/travelBooking/routes/${routeId}/stops`, {
    useAuth: tokenContext,
  });
}

export function useStopByRoute(ramal, tokenContext) {
  return useMutation(() => getStopsByRoute(ramal, tokenContext));
}

export const scheduleByMonth = async (monthParam, tokenContext) => {
  return await http.get(`/travelBooking/Schedule/${monthParam}`, {
    useAuth: tokenContext,
  });
}

export function useScheduleByMonth(month, tokenContext) {
  const monthParam = month;
  return useQuery(["scheduleBooking", monthParam], () =>
    scheduleByMonth(monthParam, tokenContext)
  );
}

export function allBooking(tokenContext, date) {
  if(date === "") {
    return http.get(`/travelBooking/Bookings`, { useAuth: tokenContext });
  }else{ 
    return http.get(`travelBooking/Bookings/${date}`, { useAuth: tokenContext });
  }
}

export function useAllBooking(tokenContext, date) {
  return useQuery(["allBooking"], () => allBooking(tokenContext, date));
}

export function cancelMyBooking(idBooking, tokenContext) {
  return http.post(
    `/travelBooking/cancel/${idBooking}`,
    {},
    { useAuth: tokenContext }
  );
}

export const useCancelMyBooking = (tokenContext) => {
  const queryClient = useQueryClient();
  return useMutation((idBooking) => cancelMyBooking(idBooking, tokenContext), {
    onSuccess: () => {
      queryClient.invalidateQueries(["allBooking"]);
      queryClient.invalidateQueries(["scheduleBooking"]);
    },
  });
};

export function previewBooking(previewObject, tokenContext) {
  return http.post(`/travelBooking/Preview`, previewObject, {
    useAuth: tokenContext,
  });
}

export function previewBookingModification(previewObject, tokenContext) {
  return http.post(`/travelBooking/Preview/modification`, previewObject, {
    useAuth: tokenContext,
  });
}

export const usePreviewBooking = (tokenContext) => {
  return useMutation((previewObject) =>
    previewBooking(previewObject, tokenContext)
  );
};

export const usePreviewBookingModification = (tokenContext) => {
  return useMutation((previewObject) =>
    previewBookingModification(previewObject, tokenContext)
  );
};

export function confirmBooking(confirmObject, tokenContext) {
  return http.post(`/travelBooking/previewReservation/confirm`, confirmObject, {
    useAuth: tokenContext,
  });
}

export const useConfirmBooking = (tokenContext) => {
  const queryClient = useQueryClient();
  return useMutation(
    (confirmObject) => confirmBooking(confirmObject, tokenContext),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["scheduleBooking"]);
      },
    }
  );
};

export function getToken(personToken) {
  return http.post(`/person/authenticate/${personToken}`);
}
