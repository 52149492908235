import axios from "axios";
import { API_URL } from "../../constants/config";

const http = axios.create();

// const isProduction = process.env.REACT_API_URL;

http.interceptors.request.use(
  (config) => {
    const { useAuth = false } = config;
    return {
      ...{ baseURL: API_URL },
      ...config,
      headers: {
        ...config.headers,
        ...(useAuth && { Authorization: `Bearer ${useAuth}`})
    },
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default {
  get(url, config) {
    return http.get(url, config);
  },
  post(url, data, config) {
    return http.post(url, data, config);
  },
  patch(url, data, config) {
    return http.patch(url, data, config);
  },
  delete(url, config) {
    return http.delete(url, config);
  },
};