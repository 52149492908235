import "./boxReservation.modules.scss";
import Button from "../../../share/button/Button";

export default function BoxReservationAll({reservation, setIsModalOpened, key}) {
  return (
    <div className="myReservations" key={key}>
      <div className="reservationInfo">
        <div className="infoLeft">
          <span>
            Pasajero : <strong>{reservation.Passenger}</strong>
          </span>
          <span>
            Punto de partida : <strong>{reservation.Stop}</strong>
          </span>
          <span>
            DNI : <strong>{reservation.Identification}</strong>
          </span>
        </div>
        <div className="infoRight">
          <span>
            Horario : <strong>{reservation.Time}</strong>
          </span>
          <span>
            Fecha : <strong>{reservation.Date}</strong>
          </span>
        </div>
      </div>
      {reservation.CanDelete === true ? (
        <Button
          type="button"
          onClick={() => {
            setIsModalOpened(reservation.Id);
          }}
        >
          Eliminar reserva
        </Button>
      ) : (
        <p>
          Esta reserva no puede ser eliminada ya que esta muy proxima a la fecha
        </p>
      )}
    </div>
  );
}
