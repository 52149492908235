import { useState, useMemo, useEffect } from "react";
import Booking from "../booking/booking"
import Loading from "../../components/loading/loading";
import { useLocation } from "react-router-dom";
import { getToken } from "../../hooks/useBooking";
import LoginContextModal from "../../context/token.context";

export default function Transport() {
  const query = useQuery();
  const TKN = query.get("tkn");

  const [token, setToken] = useState("");

  useEffect(() => {
    if (TKN !== null) {
      getToken(TKN)
        .then((res) => {
          setToken(res.Token);
        })
        .catch((err) => {
          console.log("error");
        });
    }
  }, []);


  if (token === "") {
    return <Loading overlay={true} />;
  } else {
    return (
      <LoginContextModal.Provider value={token}>
        <Booking />
      </LoginContextModal.Provider>
    );
  }

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
}
